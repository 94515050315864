module.exports = Object.freeze({
   // API_URL: "http://localhost:80/tres/php/",
   // API_URL: "https://tresculturaschallenge.com/php",
   // FRONTEND_URL: "localhost:8080",
   // API_URL: "http://localhost:80/php",
   // PROD ENV
   API_URL: "https://tresculturaschallenge.com.mx/php",
   FRONTEND_URL: "https://tresculturaschallenge.com.mx",
   APP_NAME: "Tres Culturas Challenge",
   DEVELOP_MODE: true
});