import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
   {
      path: "/state",
      name: "State",
      component: () => import("./views/State.vue"),
      meta: {
         title: "State",
         icon: "",
         show: false,
         hidden: true,
      },
   },
   {
      path: "/dashboard",
      name: "Dashboard",
      component: () => import("./views/Dashboard.vue"),
      meta: {
         title: "Dashboard",
         icon: "",
         show: false,
         hidden: true,
      },
   },
   {
      path: "/login",
      name: "Login",
      component: () => import("./views/Login.vue"),
      meta: {
         title: "",
         icon: "",
         show: false,
         hidden: false,
      },
   },
   {
      path: "/wods",
      name: "Wods",
      component: () => import("./views/Wods.vue"),
      meta: {
         title: "Wods",
         icon: "mdi-clipboard-clock-outline",
         show: true,
         hidden: true,
      },
   },
   {
      path: "/atletas",
      name: "Atletas",
      component: () => import("./views/Atletas.vue"),
      meta: {
         title: "Atletas",
         icon: "mdi-weight-lifter",
         show: true,
         hidden: true,
      },
   },
   {
      path: "/puntuaciones",
      name: "Puntuaciones",
      component: () => import("./views/Puntuaciones.vue"),
      meta: {
         title: "Puntuaciones",
         icon: "mdi-counter",
         show: true,
         hidden: true,
      },
   },
   {
      path: "/scores",
      name: "Posiciones",
      component: () => import("./views/Posiciones.vue"),
      meta: {
         title: "Posiciones",
         icon: "mdi-podium",
         show: true,
         hidden: true,
      },
   },
   {
      path: "/tops",
      name: "Generales",
      component: () => import("./views/Generales.vue"),
      meta: {
         title: "Generales",
         icon: "mdi-medal",
         show: true,
         hidden: true,
      },
   },
   {
      path: "/",
      name: "Main",
      component: () => import("./views/Main.vue"),
      meta: {
         title: "Main",
         icon: "mdi-home-outline",
         show: true,
         hidden: false,
      },
   },
   {
      path: "/imagenes",
      name: "Imagenes",
      component: () => import("./views/Imagenes.vue"),
      meta: {
         title: "Imagenes",
         icon: "mdi-home-outline",
         show: false,
         hidden: false,
      },
   },
   {
      path: "/usuarios",
      name: "Usuarios",
      component: () => import("./views/Usuarios.vue"),
      meta: {
         title: "Usuarios",
         icon: "mdi-account",
         show: true,
         hidden: true,
      },
   },
];

const router = new VueRouter({
   routes,
});

export default router;
