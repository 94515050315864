import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

let darkt = false;
if(localStorage.getItem("dark")){
   darkt = JSON.parse(localStorage.getItem("dark"));
}

export default new Vuetify({
   theme: {
      dark: darkt,
      themes: {
         dark: {
            primary: colors.shades.black,
            secondary: colors.grey.darken1,
            accent: colors.grey.lighten1,
         },
         light: {
            primary: colors.grey.lighten2,
            secondary: colors.grey,
            accent: colors.grey.lighten1,
         },
      },
   },
});
