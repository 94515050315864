import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import c from "./constant";

Vue.use(Vuex);
axios.defaults.baseURL = c.API_URL;
axios.defaults.headers.common["Cache-Control"] = "no-cache";
axios.defaults.headers.common["Pragma"] = "no-cache";
axios.defaults.headers.common["Expires"] = "0";

const getCacheableState = () => {
   return {
      username: "",
      token: "",
   };
};
const getDefaultState = () => {
   return {
      userdata: getCacheableState(),
      wods: [],
      atletas: [],
      puntuaciones: {items: [], totalItems: 0},
      puntuaciones_score: [],
      generales: [],
      public_wods: [],
      public_atletas: [],
      public_puntuaciones: [],
      public_generales: [],
   };
};

export default new Vuex.Store({
   state: getDefaultState(),
   mutations: {
      initializeStore(state) {
         if (localStorage.getItem("userdata")) {
            let userdata = JSON.parse(localStorage.getItem("userdata"));
            state.userdata = userdata;
            axios.defaults.headers.common["Authorization"] = "Bearer " + state.userdata.token;
         }
      },
      logout() {
         this.replaceState(getDefaultState());
      },
      login(state, payload) {
         state.userdata = payload;
      },
      wods(state, payload) {
         state.wods = payload;
      },
      atletas(state, payload) {
         state.atletas = payload;
      },
      puntuaciones(state, payload) {
         state.puntuaciones.items = payload.items;
         state.puntuaciones.totalItems = payload.totalItems;
      },
      puntuaciones_score(state, payload) {
         state.puntuaciones_score = payload;
      },
      generales(state, payload) {
         state.generales = payload;
      },
      public_wods(state, payload) {
         state.public_wods = payload;
      },
      public_atletas(state, payload) {
         state.public_atletas = payload;
      },
      public_puntuaciones(state, payload) {
         state.public_puntuaciones = payload;
      },
      public_generales(state, payload) {
         state.public_generales = payload;
      },
   },
   actions: {
      async login(context, payload) {
         try {
            let res = await axios.post("login.php", {
               username: payload.username,
               password: payload.password,
            });
            context.commit("login", res.data);
            axios.defaults.headers.common["Authorization"] = "Bearer " + context.state.userdata.token;
            return true;
         } catch (error) {
            console.log(error);
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      //GET
      async getWods(context) {
         try {
            let res = await axios.get("wods.php");
            context.commit("wods", res.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getAtletas(context) {
         try {
            let res = await axios.get("atletas.php");
            context.commit("atletas", res.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getPuntuacionesScore(context, payload) {
         try {
            const res = await axios.get("puntuaciones.php", {
               params: {
                  pagination: false,
                  desc: payload?.desc ?? 'desc'
               },
            });
            context.commit("puntuaciones_score", res.data);
            return true;
         } catch (error) {
            console.log(error);
         }
      },
      async getPuntuaciones(context, payload) {
         try {
            let res = await axios.get("puntuaciones.php", {
               params: {
                  page: payload?.page,
                  itemsPerPage: payload?.itemsPerPage,
                  pagination: payload?.pagination ?? false,
                  sortBy: payload?.sortBy,
                  sortDesc: payload?.sortDesc,
                  search: payload?.search,
               }
            });
            context.commit("puntuaciones", {
               items: res.data.items ?? [],
               totalItems: parseInt(res.data.totalItems) ?? 0
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getGenerales(context) {
         try {
            let res = await axios.get("generales.php");
            context.commit("generales", res.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getPublicWods(context) {
         try {
            let res = await axios.get("main/wods.php");
            context.commit("public_wods", res.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getPublicAtletas(context) {
         try {
            let res = await axios.get("main/atletas.php");
            context.commit("public_atletas", res.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getPublicPuntuaciones(context) {
         try {
            let res = await axios.get("main/puntuaciones.php");
            context.commit("public_puntuaciones", res.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getPublicGenerales(context) {
         try {
            let res = await axios.get("main/generales.php");
            context.commit("public_generales", res.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getAtletaInfo(context, payload) {
         try {
            let res = await axios.get("main/atleta_info.php", { params: { num: payload.num }});
            return res.data;
         } catch (error) {
            if (c.DEVELOP_MODE) 
               console.error(error);
            return {
               status: 400
            };
         }
      },
      async getUsuarios(context, payload) {
         try {
            let res = await axios.get("main/usuarios.php");
            return res.data;    
         } catch (error) {
            if (c.DEVELOP_MODE)
               console.error(error);
            return {
               status: 400
            };
         }
      },
      async getImage(context, { puntuacion_id, url }) {
         try {
            const response = await axios.get("imagenes.php", {
               params: {
                  puntuacion_id,
                  url
               }
            });
            return response;
         } catch (error) {
            if (c.DEVELOP_MODE)
               console.error(error);
            return error;
         }
      },
      async getImageGeneral(context, { atleta_id, wod_name }) {
         try {
            const response = await axios.get("imagenes.php", {
               params: {
                  atleta_id,
                  wod_name
               }
            });
            return response;
         } catch (error) {
            if (c.DEVELOP_MODE)
               console.error(error);
            return error;
         }
      },
      //INSERT
      async insertWod(context, payload) {
         try {
            await axios.post(`wods.php`, payload, {
               headers: {
                  "Content-Type": "multipart/form-data",
               },
            });
            context.dispatch("getWods");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async insertAtleta(context, payload) {
         try {
            await axios.post(`atletas.php`, payload);
            context.dispatch("getAtletas");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async insertPuntuacion(context, payload) {
         try {
            const response = await axios.post(`puntuaciones.php`, payload);
            if (response.status === 302)
               return false;

            context.dispatch("getPuntuaciones", {
               page: payload.options.page,
               itemsPerPage: payload.options.itemsPerPage,
               pagination: true,
               sortBy: payload.options.sortBy,
               sortDesc: payload.options.sortDesc,
               search: payload.options.search,
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async insertUser(context, { username, password }) {
         try {
            const { status } = await axios.post(`usuarios.php`, {
               data: {
                  username,
                  password
               }
            });
            context.dispatch("getUsuarios");
            return status;
         } catch (error) {
            if (c.DEVELOP_MODE)
               console.error(error);
            return error;
         }
      },
      async insertImage(context, { imageURL, atleta_id, wod }) {
         try {
            await axios.post("imagenes.php", {
               data: {
                  imageURL,
                  atleta_id,
                  wod
               }
            })
         } catch (error) {
            if (c.DEVELOP_MODE)
               console.error(error);
            return error;
         }
      },
      //UPDATE
      async updateWod(context, payload) {
         try {
            await axios.put(`wods.php`, payload);
            context.dispatch("getWods");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updateAtleta(context, payload) {
         try {
            await axios.put(`atletas.php`, payload);
            context.dispatch("getAtletas");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updatePuntuacion(context, payload) {
         try {
            await axios.put(`puntuaciones.php`, payload);
            context.dispatch("getPuntuaciones", {
               page: payload.options.page,
               itemsPerPage: payload.options.itemsPerPage,
               pagination: true,
               sortBy: payload.options.sortBy,
               sortDesc: payload.options.sortDesc,
               search: payload.options.search,
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updateScore(context, payload) {
         try {
            await payload.forEach(async (element) => {
               await axios.put(`puntuaciones/score.php`, element);
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async sendGenerales(context, payload) {
         try {
            await payload.forEach(async (element) => {
               await axios.put(`puntuaciones/enviar_generales.php`, element);
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      // Enviar las puntuaciones indicadas
      async sendPuntuaciones(context, payload) {
         try {
            await payload.forEach(async element => {
               await axios.put(`puntuaciones/enviar_imagen.php`, element);
            })
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE)
               console.log(error);
            return error;
         }
      },
      // Envia todos las puntuaciones
      async sendAllPuntuaciones(context, payload) {
         try {
            console.log('payload',payload)
            const response = await axios.put(`puntuaciones/enviar_imagen.php`, payload);
            console.log('response',response )
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE)
               console.log(error);
            return error;
         }
      },
      async updateUser(context, { usuario_id, username, password }) {
         try {
            const { status } = await axios.put("usuarios.php", {
               data: {
                  usuario_id,
                  username,
                  password
               }
            });
            context.dispatch("getUsuarios");
            return status;
         } catch (error) {
            if (c.DEVELOP_MODE)
               console.error(error);
            return error;
         }
      },
      async updateImage(context, { imageURL, atleta_id, wod, puntuacion_id }) {
         try {
            await axios.put("imagenes.php", {
               data: {
                  imageURL,
                  atleta_id,
                  wod,
                  puntuacion_id
               }
            })
         } catch (error) {
            if (c.DEVELOP_MODE)
               console.error(error);
            return error;
         }
      },
      //DELETE
      async deleteWod(context, payload) {
         try {
            await axios.delete("wods.php", {
               data: {
                  wod_id: payload.wod_id,
               },
            });
            context.dispatch("getWods");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async deleteAtleta(context, payload) {
         try {
            await axios.delete("atletas.php", {
               data: {
                  atleta_id: payload.atleta_id,
               },
            });
            context.dispatch("getAtletas");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async deletePuntuacion(context, payload) {
         try {
            await axios.delete("puntuaciones.php", {
               data: {
                  puntuacion_id: payload.puntuacion_id,
               },
            });
            context.dispatch("getPuntuaciones", {
               page: payload.options.page,
               itemsPerPage: payload.options.itemsPerPage,
               pagination: true,
               sortBy: payload.options.sortBy,
               sortDesc: payload.options.sortDesc,
               search: payload.options.search,
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async deleteUser(context, { usuario_id }) {
         try {
            const response = await axios.delete("usuarios.php", {
               data: {
                  usuario_id
               }
            });
            context.dispatch("getUsuarios");
            return response;
         } catch (error) {
            if (c.DEVELOP_MODE)
               console.error(error.response);
            return error;
         }
      }
   },
});
