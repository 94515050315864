import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import constant from "./constant";
import "./registerServiceWorker";

import Dialog from "./components/Dialog";
import Notification from "./components/Notification";
import Header from "./components/Header";
import Footer from "./components/Footer";
import JsonViewer from 'vue-json-viewer'
import Vuelidate from "vuelidate";

Vue.use(JsonViewer);
Vue.use(Vuelidate);
Vue.component("Dialog", Dialog);
Vue.component("Notification", Notification);
Vue.component("Header", Header);
Vue.component("Footer", Footer);
Vue.config.productionTip = false;
Vue.prototype.$c = constant;

store.subscribe((mutation, state) => {
   if(state.userdata){
      localStorage.setItem("userdata", JSON.stringify(state.userdata));
   }
});

new Vue({
   router,
   store,
   vuetify,
   beforeCreate() {
      store.commit("initializeStore");
   },
   render: (h) => h(App),
}).$mount("#app");

router.beforeEach(async function (to, from, next) {
   try {
      if (to.meta.hidden) {
         if (store.state.userdata.token) {
            if (store.state.userdata.token.length > 0) {
               next();
               return;
            }
         }
      } else {
         next();
         return;
      }
      router.push({
         name: "Main",
         query: {
            status: "denied",
            time: new Date().getTime(),
         },
      });
   } catch (error) {
      next(error);
   }
});
